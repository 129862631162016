import React, { useRef } from "react"

export default function Video({ video, poster, isMobile }) {
  const videoRef = useRef(null)

  const playVideo = async () => {
    if (videoRef.current) {
      try {
        await videoRef.current.play()
      } catch (error) {
        console.error("Error occurred while playing the video:", error)
      }
    }
  }

  const pauseVideo = () => {
    if (videoRef.current && !videoRef.current.paused) {
      videoRef.current.pause()
    }
  }

  const resetVideo = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0
    }
  }

  const handleMouseEnter = () => {
    playVideo()
  }

  const handleMouseLeave = () => {
    pauseVideo()
  }

  const handleOnEnded = () => {
    resetVideo()
  }

  return (
    <video
      className="aspect-video h-full w-full object-cover"
      muted
      playsInline
      poster={poster.publicURL}
      disableRemotePlayback
      preload={!isMobile ? "auto" : "none"}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onEnded={handleOnEnded}
      ref={videoRef}
    >
      {!isMobile && (
        <>
          <source src={video.webm?.publicURL} type="video/webm" />
          <source src={video.mp4?.publicURL} type="video/mp4" />
        </>
      )}
    </video>
  )
}
